<template>
        <div id="section_header" class="no-print">
            <div class="container-fluid">
                <div class="row align-items-center no-gutters">
                    <back-button :customAction="back_action" />
                    <div class="col order-md-last page-titre">
                        <div class="row">
                            <h1 class="mb-0" v-if="title && isArchived"><strike>{{ title }}</strike></h1>
                            <h1 class="mb-0" v-else-if="title">{{ title }}</h1>
                            <h3 class="mb-0 ml-2 align-self-center" v-if="accounting_plan">- {{ accounting_plan.accountingplan_label }}</h3>
                            <div v-if="show_tag">
                                <TagsManagement :fk="fk" :type="type" />
                            </div>
                        </div>
                        <RecapHorse v-if="horse" ref="horse_recap" :horse="horse" :presence="presence" :horseIntraLocation="horseIntraLocation" :horse_readonly="horse_readonly" />
                        <div id="subtitles" v-if="element && element.subtitle">
                            {{ element.subtitle }}
                        </div>
                    </div>
                    <div class="col-12 col-md-auto">
                        <!-- image cheval ou image tier -->
                        <div v-if="horse" id="horse_photo" class="mr-md-4">
                            <div v-if="!horse_readonly" :class="horse.media_filename != null && upload_state == null ? 'photoUploader2 d-md-none' : 'photoUploader'">
                                <input
                                    @change="onPhotoChange"
                                    class="d-none"
                                    ref="photo-input"
                                    type="file"
                                    accept="image/png, image/jpeg, image/webp"
                                />
                                <a
                                    v-if="upload_state === null"
                                    href=""
                                    @click.prevent="changePhoto"
                                    class="d-md-none"
                                >
                                    <font-awesome-icon :icon="['fal', 'camera']" />
                                </a>
                                <a
                                    v-if="upload_state === null && horse.media_filename == null"
                                    href=""
                                    @click.prevent="changePhoto"
                                >
                                    <font-awesome-icon :icon="['fal', 'camera']" />
                                </a>
                                <LoadingSpinner
                                    v-else-if="upload_state === 'uploading'"
                                    class="p-0 d-inline" text_trad=""
                                />
                                <font-awesome-icon
                                    v-else-if="upload_state === 'error'"
                                    @click="upload_state = null"
                                    :icon="['far', 'times-circle']"
                                />
                                <font-awesome-icon
                                    v-else-if="upload_state === 'success'"
                                    @click="upload_state = null"
                                    :icon="['far', 'check-circle']"
                                />
                            </div>
                            <div v-if="!horse_readonly && upload_state == null && horse.media_filename != null" class="photoDelete d-md-none">
                                <a
                                    v-if="upload_state === null"
                                    href=""
                                    @click.prevent="openDeleteModal"
                                >
                                    <font-awesome-icon :icon="['fal', 'trash-alt']" />
                                </a>
                            </div>
                            <Photo
                                class="d-md-none"
                                @click="changePhoto"
                                :filename="horse.media_filename"
                                type="smallScreen"
                            />
                            <Photo
                                class="d-none d-md-block"
                                @click="changePhoto"
                                :filename="horse.media_filename"
                                type="largeScreen"
                                @openModal="openPhotoModal"
                            />
                        </div>

                        <div v-if="tiers" id="horse_photo" class="mr-md-4">
                            <div :class="tiers.media_filename != null && upload_state == null ? 'photoUploader2 d-md-none' : 'photoUploader'">
                                <input
                                    @change="onPhotoChangeTiers"
                                    class="d-none"
                                    ref="photo-input"
                                    type="file"
                                    accept="image/png, image/jpeg, image/webp"
                                />
                                <a
                                    v-if="upload_state === null"
                                    href=""
                                    @click.prevent="changePhoto"
                                    class="d-md-none"
                                >
                                    <font-awesome-icon :icon="['fal', 'camera']" />
                                </a>
                                <a
                                    v-if="upload_state === null && tiers.media_filename == null"
                                    href=""
                                    @click.prevent="changePhoto"
                                >
                                    <font-awesome-icon :icon="['fal', 'camera']" />
                                </a>
                                <LoadingSpinner
                                    v-else-if="upload_state === 'uploading'"
                                    class="p-0 d-inline" text_trad=""
                                />
                                <font-awesome-icon
                                    v-else-if="upload_state === 'error'"
                                    @click="upload_state = null"
                                    :icon="['far', 'times-circle']"
                                />
                                <font-awesome-icon
                                    v-else-if="upload_state === 'success'"
                                    @click="upload_state = null"
                                    :icon="['far', 'check-circle']"
                                />
                            </div>
                            <div v-if="upload_state == null && tiers.media_filename != null" class="photoDelete  d-md-none">
                                <a
                                    v-if="upload_state === null"
                                    href=""
                                    @click.prevent="openDeleteModal"
                                >
                                    <font-awesome-icon :icon="['fal', 'trash-alt']" />
                                </a>
                            </div>
                            
                            <Photo
                                v-if="tiers.media_filename"
                                class="d-md-none"
                                @click="changePhoto"
                                :filename="tiers.media_filename"
                                type="smallScreen"
                            />
                            <Photo
                                v-if="tiers.media_filename"
                                class="d-none d-md-block"
                                @click="changePhoto"
                                :filename="tiers.media_filename"
                                type="largeScreen"
                                @openModal="openPhotoModal"
                            />
                            <Picto :icon="['fal', 'user-tie']" v-else/>
                        </div>

                        <div v-if="element && element.need_photo === true" id="element_photo" class="mr-4">
                            <Photo :filename="element.filename" :img_id="element.id_photo" :alt="element.alt" v-if="element.type == 'photo' || element.type == ''"/>
                            <Picto :icon="element.icon" v-else/>
                        </div>
                    </div>
                    <div v-if="element && element.has_group === true" class="col order-md-last">
                        {{ $t('tiers.menu_groupe') }} :
                        <p v-for="(group, key) in element.groups" :key="key" class="mb-0">
                            <template v-if="group.tiers_archive">
                                <s>
                                    <router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: group.tiers_id }}">{{group.tiers_rs}}</router-link>
                                </s>
                            </template>
                            <template v-else>
                                <router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: group.tiers_id }}">{{group.tiers_rs}}</router-link>
                            </template>
                        </p>
                    </div>
                    <div class="col order-md-last text-right" v-if="(tiers && tiers.is_gocardless === true) || lang">
                        <span v-if="tiers && tiers.is_gocardless === true">
                            <img v-on:click="goGocardless(tiers.tiers_id)" role="button" class="mt-1" :src="require(`GroomyRoot/assets/img/gocardless.png`)">
                        </span>
                        <span v-if="lang">
                            <font-awesome-icon :icon="['far', 'globe']"></font-awesome-icon>
                            {{ lang.toUpperCase() }}
                        </span>
                    </div>
                </div>
                <Swiper v-if="swiper_tabs && swiper_tabs.length > 0" :tabs="swiper_tabs" :action="swiper_action" />
            </div>
            <b-modal ref="delete_confirmation" hide-footer>
                <template v-slot:modal-title>
                    {{ $t("photo.confirmation_suppression") }}
                </template>
                <div class="row justify-content-around">
                    <b-button class="mt-3 btn-block rounded-pill col-5" block @click.prevent="deletePhoto" variant="primary">{{ $t("global.oui") }}</b-button>
                    <b-button class="mt-3 btn-block rounded-pill col-5" block @click.prevent="closeDeleteModal" variant="primary">{{ $t("global.non") }}</b-button>
                </div>
	        </b-modal>

            <b-modal ref="modal_zoom" hide-footer size="xxl">

                <template v-slot:modal-title>
                    {{ $t("global.photo") }}
                </template>

                <Photo
                    v-if="horse"
                    class="d-none d-md-block justify-content-center"
                    @click="changePhoto"
                    :filename="horse.media_filename"
                    type="disable"
                />
                <Photo
                    v-if="tiers"
                    class="d-none d-md-block justify-content-center"
                    @click="changePhoto"
                    :filename="tiers.media_filename"
                    type="disable"
                />

                <div class="mt-2 row justify-content-around">
                    <b-button @click="openDeleteModal" variant="primary" class="col-auto">{{ $t("global.supprimer") }}</b-button>
                    <b-button @click="changePhoto" variant="primary" class="col-auto">{{ $t("global.modifier") }}</b-button>
                </div> 

            </b-modal>
            <b-modal ref="modal_google_image" hide-footer :size="modalImageSize">

                <template v-slot:modal-title>
                    {{ $t("global.photo") }}
                </template>
                <div v-if="!search_image" class="text-center">
                    <b-button @click="activateSearch" variant="primary">{{ $t("global.rechercher_internet") }}</b-button>
                    <b-button @click="uploadPhoto" variant="primary" class="ml-3">{{ $t("action.importer") }}</b-button>
                    <b-button variant="secondary" class="ml-3" @click="closeGoogleImageModal">{{ $t("global.annuler") }}</b-button>
                </div>
                <div v-else class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-4 text-center">
                        <b-button @click="activateSearch" variant="primary">{{ $t("global.rechercher_internet") }}</b-button>
                        <b-button @click="uploadPhoto" variant="primary" class="ml-3">{{ $t("action.importer") }}</b-button>
                    </div>
                    <div class="col-4">
                        <p>{{ $t("global.droits_auteur") }}</p>
                    </div>
                </div>
                <div v-if="search_image" class="mt-2">
                    <div class="row">
                        <div class="col-4">
                                <input type="text" id="image_search" class="form-control" v-model="image_search_query" />
                        </div>
                        <b-button @click="searchImages" :disabled="processing_search" variant="primary" class="col-auto">
                            <span v-if="!processing_search">{{ $t("global.rechercher") }}</span>
                            <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse/>
                        </b-button>
                    </div>
                    <div class="row" v-if="processing_search">
                        <LoadingSpinner />
                    </div>
                    <div class="row mt-2 mx-2" v-if="!processing_search && searched_images && searched_images.length > 0">
                        <EImageSelect :images="searched_images" @imageSelected="(i) => selectImage(i)"/>
                    </div> 
                    <div class="text-center mt-2">
                        <b-button variant="secondary" class="ml-2" @click="closeGoogleImageModal">{{ $t("global.annuler") }}</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
</template>

<script type="text/javascript">
    import Countries from '@/assets/lang/countries/countries'
	import { EventBus } from 'EventBus'
	import Navigation from '@/mixins/Navigation.js'
	import Horse from '@/mixins/Horse.js'
	import Medias from '@/mixins/Medias.js'
	import Tiers from '@/mixins/Tiers.js'
    import Vue from 'vue'

	export default {
        name: "HeaderTab",
        mixins: [Countries, Navigation, Horse, Medias, Tiers],
        props: {
            title: { type: String, default: '' },
            horse: { type: Object, default: null },
            accounting_plan: { type: Object, default: null },
            presence: { type: Object, default: null },
            horseIntraLocation: { type: Object, default: null },
            swiper_action: { type: Function, default: null },
            element: { type: Object, default: null },
            swiper_tabs: { type: Array, default: () => [] },
            horse_readonly: { type: Boolean, default: false },
            back_action: { type: Function, default: null },
            tiers: { type: Object, default: null },
            show_tag: { type: Boolean, default: false }
        },
        components: {
            Swiper: () => import('GroomyRoot/components/Swiper'),
            EImageSelect: () => import('GroomyRoot/components/Utils/EImageSelect'),
            RecapHorse: () => import('@/components/RecapHorse'),
            Photo: () => import('@/components/Header/Photo'),
            Picto: () => import('@/components/Header/Picto'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            TagsManagement: () => import('@/components/TagsManagement'),
            Phone: () => import('@/components/Tiers/Phone'),
		    TiersModal: () => import('@/components/Tiers/Form')
        },
		data () {
			return {
				is_updating: false,
                upload_state: null,
                image_search_query: "",
                searched_images: [],
                search_image: false,
                processing_search: false
            }
        },
        mounted() {
            //EventBus.$on('UpdateManager::setStatus', this.setSyncNotif)
        },
        methods: {
			setSyncNotif(data) {
				if(data.type == "") {
					this.is_updating = false
					return
				}
				this.is_updating = true
			},
            openDeleteModal() {
                this.$refs.delete_confirmation.show()
            },
            closeDeleteModal() {
                this.$refs.delete_confirmation.hide()
            },
            openPhotoModal() {
                this.$refs.modal_zoom.show()
            },
            closePhotoModal() {
                this.$refs.modal_zoom.hide()
            },
            changePhoto() {
                this.search_image = false
                this.searched_images = []
                this.image_search_query = ""
                // Modal photo google image si tiers
                if (!this.tiers){
                    return this.$refs['photo-input'].click()
                }
                this.$refs.modal_google_image.show()
                this.activateSearch()
                this.searchImages()
            },
            closeGoogleImageModal() {
                this.$refs.modal_google_image.hide()
            },
            uploadPhoto(){
                this.$refs.modal_google_image.hide()
                return this.$refs['photo-input'].click()
            },
            async searchImages() {
                this.processing_search = true
                const result = await this.getGoogleImagePics(this.image_search_query)
                if (result) {
                    this.searched_images = result
                }
                this.processing_search = false
            },
            getUrlExtension(url) {
                return url
                //eslint-disable-next-line
                .match(/([^\/]+$)/)[0];
            },

            async selectImage(i) {
                this.processing_search = true
                this.upload_state = 'uploading'
                try {
                    const image_link = this.searched_images[i].file_url
                    let image = await this.getPicFileFromLink(image_link)
                    let type = this.checkImageType(image)
                    if (type === null){
                        this.processing_search = false
                        return this.failureToast('toast.invalid_image')
                    }
                    var imgExt = this.getUrlExtension(type);
                    const file = await fetch('data:image/png;base64,' + image)
                    .then(res => res.blob())
                    .then(blob => {
                        return new File([blob], 'tiers_' + this.tiers.tiers_id.toString() + '_image.' + imgExt,{ type: type })
                    })
                    const media_filename = await this.uploadMediaTiers(
                        file,
                        this.tiers.tiers_id
                    )
                    this.tiers.media_filename = media_filename

                    this.upload_state = 'success'
                } catch (err) {
                    console.error(err)
                    this.failureToast('error.UKE')
                    this.upload_state = 'error'
                    this.processing_search = false
                }
                this.closeGoogleImageModal()
                this.closePhotoModal()
                this.processing_search = false
            },
            checkImageType(image){
                return image.charAt(0) == '/' ? 'image/jpg' : 
                image.charAt(0) == 'i' ? 'image/png' : 
                image.charAt(0) == 'U' ? 'image/webp' : 
                null
            },
            activateSearch() {
                this.search_image = true
                this.image_search_query = this.tiers.tiers_rs ?? this.tiers.tiers_firstname + " " + this.tiers.tiers_lastname
            },
            async deletePhoto() {
                if(this.horse){
                    await this.deletedMedia(this.horse.media_filename, this.horse.horse_id)
                    this.horse.media_filename = null
                }
                if(this.tiers){
                    await this.deleteMediaTiers(this.tiers.media_filename, this.tiers.tiers_id)
                    this.tiers.media_filename = null
                }
                this.closeDeleteModal()
                this.closePhotoModal()
            },
            async onPhotoChange(evt) {
                this.upload_state = 'uploading'
                try {
                    const file = evt.target.files[0]
                    evt.target.value = null

                    const media_filename = await this.uploadMedia(
                        file,
                        this.horse.horse_id
					)

					this.horse.media_filename = media_filename

                    this.upload_state = 'success'
                } catch (err) {
					console.error(err)
                    this.failureToast('error.UKE')
                    this.upload_state = 'error'
                }
            },
            async onPhotoChangeTiers(evt) {
                this.upload_state = 'uploading'
                try {
                    const file = evt.target.files[0]
                    evt.target.value = null

                    const media_filename = await this.uploadMediaTiers(
                        file,
                        this.tiers.tiers_id
                    )

                    this.tiers.media_filename = media_filename

                    this.upload_state = 'success'
                } catch (err) {
                    console.error(err)
                    this.failureToast('error.UKE')
                    this.upload_state = 'error'
                }
            },
            async goGocardless(tiers_id) {
                const result = await this.getGocardlessUrl(tiers_id)
                if(result && result.gocardlessid) {
                    window.open('https://manage.gocardless.com/customers/'+result.gocardlessid, '_blank').focus();
                } else {
                    this.failureToast('error.TNG')
                }
            },
		},
        computed: {
            fk() {
                if(this.tiers) {
                    return this.tiers.tiers_id
                }
                if(this.horse) {
                    return this.horse.horse_id
                }
                return null
            },
            type() {
                if(this.tiers) {
                    return 'tiers'
                }
                if(this.horse) {
                    return 'horse'
                }
                return null
            },
            lang() {
                if(this.tiers && this.tiers.tiers_lang) {
                    // const code = this.tiers.tiers_lang == 'en' ? 'GB' : this.tiers.tiers_lang
                    // const key = Object.keys(Countries[Vue.i18n.locale()]['iso']).find(key => Countries[Vue.i18n.locale()].iso[key] === code.toUpperCase())
                    // return Countries[Vue.i18n.locale()].iso_3[key] ? Countries[Vue.i18n.locale()].iso_3[key] : 'FRA'
                    return this.tiers.tiers_lang
                }
                return null
            },
            isArchived() {
                return (this.horse && this.horse.horse_inactive == 1) || (this.tiers && this.tiers.tiers_archive == 1)
            },
            modalImageSize(){
                return this.search_image ? "xxl" : "sm"
            }
        }
	}
</script>
