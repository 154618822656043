import Constants from '../../config.local.js'

var MediaMixin = {
	methods: {
        async getMediaAsFile(media_id) {
            const url = this.constructRoute(Constants.MEDIAS_FILE_URL, { media_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY
            const res = await this.$request.request_get_api("MediasMixin::getMediaAsFile", url)
            return res.media
        },
        async getMediaById(media_id) {
            return this
            .storage
            .db
            .t('horse_media')
            .then(table => {
                return table.get(parseInt(media_id))
            })
        }
    }
}

export default MediaMixin
